import AgoraCore, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  LocalAudioTrackStats,
  LocalVideoTrackStats,
  RemoteAudioTrackStats,
  RemoteVideoTrackStats,
} from 'agora-rtc-sdk-ng';
// import Analysis from 'api/analysis';
// import {getStreamQuality} from 'api/stream';
import {AgoraAppID} from 'config';

import {Optional} from 'utils/types';

import {LivestreamModel, WatchMode} from './LivestreamTypes';
// import {StreamQuality, WatchMode} from 'model/Stream';
// import {UAParser} from 'ua-parser-js';
import {RTC, StreamDevice} from './RTC';

declare const AgoraRTS: any;

type LocalStream = {
  audioTrack: Optional<IMicrophoneAudioTrack>;
  videoTrack: Optional<ICameraVideoTrack>;
};

type RemoteStream = {
  audioTrack: Optional<IRemoteAudioTrack>;
  videoTrack: Optional<IRemoteVideoTrack>;
};

export default class AgoraRTC implements RTC {
  public static instance = new AgoraRTC();

  private static readonly TRACK_STATS_INTERVAL = 5000;
  private analyzer = 0;
  // private ua: IUAParser.IResult;

  private engine?: IAgoraRTCClient;
  private localStream: LocalStream = {
    audioTrack: null,
    videoTrack: null,
  };
  private playerElement?: HTMLElement | string;
  private cameraId: string | undefined;
  private remoteStream: RemoteStream = {
    audioTrack: null,
    videoTrack: null,
  };
  private joined: boolean = false;
  private microphoneMuted: boolean = false;
  private localStreamMuted: boolean = false;

  private remoteStreamMuted: boolean = false;
  private videoDevices: StreamDevice[] = [];
  private audioDevices: StreamDevice[] = [];

  private supportVideoCodec: string[] = [];
  private supportAudioCodec: string[] = [];
  private streamQuality: LivestreamModel.StreamQuality = {
    minFps: 24,
    maxFps: 30,
    minBitrate: 1368,
    maxBitrate: 2736,
    resolutionHeight: 720,
    resolutionWidth: 1280,
    codec: 'h264',
  };

  constructor() {
    // this.ua = new UAParser().getResult();

    // if (__DEV__) {
    const compatible = AgoraCore.checkSystemRequirements();
    console.log(`AgoraCore checkSystemRequirements: ${compatible}`);

    (window as any).gtag('event', 'player_compatible', {
      userAgent: navigator.userAgent,
      compatible,
    });

    AgoraCore.getSupportedCodec().then((result) => {
      console.log(`Supported video codec: ${result.video.join(',')}`);
      console.log(`Supported audio codec: ${result.audio.join(',')}`);

      this.supportAudioCodec = result.audio;
      this.supportVideoCodec = result.video;

      (window as any).gtag('event', 'support_video_codec', {
        userAgent: navigator.userAgent,
        videoCodec: `${result.video.join(',')}`,
      });

      (window as any).gtag('event', 'support_audio_codec', {
        userAgent: navigator.userAgent,
        audioCodec: `${result.audio.join(',')}}`,
      });
    });

    AgoraCore.disableLogUpload();
    AgoraCore.setLogLevel(3); // 需要顯示DEBUG訊息時改這邊
    // } else {
    //   // AgoraCore.Logger.setLogLevel(3);
    //   AgoraCore.Logger.enableLogUpload();
    // }
  }

  private initialize(mode: WatchMode): Promise<IAgoraRTCClient> {
    if (this.engine) {
      return Promise.resolve(this.engine);
    }

    return new Promise((resolve, reject) => {
      // TODO: call stream quality api
      // getStreamQuality()
      //   .then((quality) => {
      //     this.streamQuality = quality;
      //   })
      //   .finally(() => {

      const client = AgoraCore.createClient({
        mode: mode === WatchMode.OO ? 'rtc' : 'live',
        codec: this.streamQuality.codec,
      });

      // if (
      //   navigator.userAgent.toLowerCase().indexOf('android') > -1 &&
      //   AgoraRTS.checkSystemRequirements()
      // ) {
      //   AgoraRTS.init(AgoraCore, {
      //     wasmDecoderPath: '/agora/AgoraRTS.wasm',
      //     asmDecoderPath: '/agora/AgoraRTS.asm',
      //     bufferDelay: 5000,
      //   });
      //   AgoraRTS.proxy(client);
      // }

      client.on('exception', (evt) => {
        // Analysis.sendError(evt, false);
        // Analysis.log('Agora', `exception: ${JSON.stringify(evt)}`);
      });

      resolve(client);
    });
  }

  private getDefaultCameraId(devices: StreamDevice[]): string | undefined {
    return (
      this.cameraId ||
      devices.find((device) => device.name.indexOf('yy') > -1 || device.name.indexOf('youcam') > -1)
        ?.id
    );
  }

  private async initLocalStream(microphoneId: string, cameraId?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const [audioTrack, videoTrack] = await AgoraCore.createMicrophoneAndCameraTracks(
          {
            microphoneId: microphoneId,
          },
          {
            cameraId: cameraId,
          },
        );

        videoTrack.setEncoderConfiguration({
          width: this.streamQuality.resolutionWidth,
          height: this.streamQuality.resolutionHeight,

          frameRate: {
            min: this.streamQuality.minFps,
            max: this.streamQuality.maxFps,
          },
          bitrateMin: this.streamQuality.minBitrate,
          bitrateMax: this.streamQuality.maxBitrate,
        });
        if (this.microphoneMuted) {
          audioTrack.setMuted(true);
        }
        this.localStream = {
          audioTrack,
          videoTrack,
        };

        this.localStreamMuted = false;

        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  }

  private async agoraSetRole(role: 'audience' | 'host') {
    return await this.engine!.setClientRole(role);
  }

  private async agoraJoinPromise(channel: string, uid: string | number | undefined) {
    try {
      const UID = await this.engine?.join(AgoraAppID, channel, null, uid);
      this.joined = true;
      return UID;
    } catch (err) {
      this.joined = false;
      throw err;
    }
  }

  private async agoraLeaveChannel() {
    try {
      await this.engine?.leave();
    } catch (err) {
      throw err;
    } finally {
      this.joined = false;
    }
  }

  private agoraSubscribeRemoteStream(client: IAgoraRTCClient, onVideoSubscribed?: () => void) {
    try {
      client.on(
        'user-published',
        async (user: IAgoraRTCRemoteUser, mediaType: 'video' | 'audio') => {
          await client.subscribe(user, mediaType);
          const {videoTrack, audioTrack} = user;
          if (mediaType === 'video' && videoTrack) {
            this.remoteStream.videoTrack = videoTrack;
            onVideoSubscribed?.();
          }
          if (mediaType === 'audio' && audioTrack) {
            this.remoteStream.audioTrack = audioTrack;
            this.remoteStream.audioTrack.setVolume(this.remoteStreamMuted ? 0 : 100);
            this.remoteStream.audioTrack.play();
          }
        },
      );
    } catch (err) {
      throw err;
    }
  }

  private isAvailableToPlay() {
    return this.remoteStream.audioTrack != null || this.remoteStream.videoTrack != null;
  }

  private agoraPlayStream(playerElement: HTMLElement | string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (!this.isAvailableToPlay()) {
          reject('remote stream is not subscribed');
          return;
        }

        // if (this.remoteStreamMuted) {
        //   this.remoteStreamMuting = playerElement;
        // }

        this.remoteStream.videoTrack?.play(playerElement, {
          fit: 'contain',
        });
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  private agoraSwitchDevice(type: 'video' | 'audio', deviceId: string) {
    return new Promise<void>((resolve, reject) => {
      try {
        if (!this.localStream.videoTrack || !this.localStream.audioTrack) {
          reject('only local stream can switch device');
          return;
        }

        if (type === 'video') {
          this.localStream.videoTrack.setDevice(deviceId);
        }

        if (type === 'audio') {
          this.localStream.audioTrack.setDevice(deviceId);
        }
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * @deprecated agoraSubscribeRemoteStream has subscribed user-published events
   * @param client
   */
  private waitForPublish(client: IAgoraRTCClient): Promise<boolean> {
    return new Promise((resolve) => client.on('user-published', () => resolve(true)));
  }

  async detectDevices(): Promise<{video: StreamDevice[]; audio: StreamDevice[]}> {
    const ids = new Set<string>();

    return new Promise((resolve) => {
      if (this.videoDevices.length > 0) {
        resolve({video: this.videoDevices, audio: this.audioDevices});
      }

      /// some browser block getDevices function
      const timer = setTimeout(() => resolve({video: [], audio: []}), 4000);
      AgoraCore.getDevices().then((devices) => {
        clearTimeout(timer);
        devices.forEach((device) => {
          if (!ids.has(device.deviceId)) {
            ids.add(device.deviceId);
            if (device.kind === 'videoinput') {
              this.videoDevices.push({
                id: device.deviceId,
                name: device.label.toLowerCase(),
              });
            } else if (device.kind === 'audioinput') {
              this.audioDevices.push({
                id: device.deviceId,
                name: device.label.toLowerCase(),
              });
            }
          }
        });
        resolve({video: this.videoDevices, audio: this.audioDevices});
      });
    });
  }

  async prepare(
    this: AgoraRTC,
    uid: string | number,
    mode: WatchMode,
    canvasId: string,
  ): Promise<boolean> {
    try {
      await this.initialize(mode);

      const {video, audio} = await this.detectDevices();
      this.cameraId = this.getDefaultCameraId(video);

      await this.initLocalStream(audio[0].id, this.cameraId);

      if (this.localStream.videoTrack) this.localStream.videoTrack.play(canvasId, {fit: 'contain'});

      return true;
    } catch (err) {
      throw err;
    }
  }

  async join(
    uid: string | number,
    channel: string,
    mode: WatchMode,
    onVideoSubscribed?: () => void,
  ) {
    if (this.joined) {
      return;
    }

    try {
      this.joined = true;
      const client = await this.initialize(mode);
      this.engine = client;
      if (mode !== WatchMode.OO) {
        await this.agoraSetRole('audience');
      }

      this.agoraSubscribeRemoteStream(client, onVideoSubscribed);
      await this.agoraJoinPromise(channel, uid);

      if (this.localStream.videoTrack) {
        client.publish(this.localStream.videoTrack);
      }

      if (this.localStream.audioTrack) {
        client.publish(this.localStream.audioTrack);
      }
    } catch (err) {
      this.joined = false;
      throw err;
    }
  }

  private agoraGetRemoteVideoStats(): Promise<{
    [uid: string]: RemoteVideoTrackStats;
  }> {
    return new Promise((resolve, reject) => {
      if (!this.engine) {
        reject();
        return;
      }

      const stats = this.engine.getRemoteVideoStats();
      resolve(stats);
    });
  }

  private agoraGetRemoteAudioStats(): Promise<{
    [uid: string]: RemoteAudioTrackStats;
  }> {
    return new Promise((resolve, reject) => {
      if (!this.remoteStream || !this.engine) {
        reject();
        return;
      }

      const stats = this.engine.getRemoteAudioStats();
      resolve(stats);
    });
  }

  private agoraGetLocalVideoStats(): Promise<LocalVideoTrackStats> {
    return new Promise((resolve, reject) => {
      if (!this.localStream || !this.engine) {
        reject();
        return;
      }

      const stats = this.engine.getLocalVideoStats();
      resolve(stats);
    });
  }

  private agoraGetLocalAudioStats(): Promise<LocalAudioTrackStats> {
    return new Promise((resolve, reject) => {
      if (!this.localStream || !this.engine) {
        reject();
        return;
      }

      const stats = this.engine.getLocalAudioStats();
      resolve(stats);
    });
  }

  // private async networkAnalysis() {
  //   if (!this.engine) {
  //     return;
  //   }

  //   try {
  //     let localAudio: AgoraCore.LocalAudioStats | undefined;
  //     let localVideo: AgoraCore.LocalVideoStats | undefined;
  //     if (this.localStream) {
  //       localAudio = await this.agoraGetLocalAudioStats();
  //       localVideo = await this.agoraGetLocalVideoStats();
  //     }

  //     let remoteAudio: AgoraCore.RemoteAudioStats | undefined;
  //     let remoteVideo: AgoraCore.RemoteVideoStats | undefined;
  //     if (this.remoteStream) {
  //       remoteAudio = await this.agoraGetRemoteAudioStats();
  //       remoteVideo = await this.agoraGetRemoteVideoStats();
  //     }

  //     const transport = await this.agoraGetTransportStats();
  //     Analysis.log(
  //       'Agora network',
  //       JSON.stringify({
  //         localAudio,
  //         localVideo,
  //         remoteAudio,
  //         remoteVideo,
  //         transport,
  //       }),
  //     );
  //   } catch (err) {
  //     console.error(err);
  //     Analysis.log('Agora network', JSON.stringify(err));
  //   }
  // }

  async destroy() {
    clearInterval(this.analyzer);
    this.localStream?.audioTrack?.close();
    this.localStream?.videoTrack?.close();

    this.localStream = {
      audioTrack: null,
      videoTrack: null,
    };

    this.remoteStream.audioTrack?.stop();
    this.remoteStream.videoTrack?.stop();

    this.remoteStream = {
      audioTrack: null,
      videoTrack: null,
    };

    try {
      if (this.engine) {
        await this.agoraLeaveChannel();
        this.engine = undefined;
      }
      return;
    } catch (err) {
      throw err;
    }
  }

  async play(playerElement: HTMLElement | string, onAutoplayFailed: () => void): Promise<void> {
    AgoraCore.onAutoplayFailed = () => {
      this.remoteStream.audioTrack?.stop();
      onAutoplayFailed();
    };

    return new Promise<void>(async (resolve, reject) => {
      try {
        this.playerElement = playerElement;
        await this.agoraPlayStream(this.playerElement);
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  resume(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (!this.remoteStream) {
          reject('remote stream is not subscribed');
          return;
        }

        if (!this.playerElement) {
          reject('player element is not subscribed');
          return;
        }

        // Analysis.log('Agora', 'resume remote video');
        this.remoteStream.audioTrack?.play();
        this.remoteStream.videoTrack?.play(this.playerElement);
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  pause(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.remoteStream) {
        reject('remote stream is not subscribed');
        return;
      }

      this.remoteStream.audioTrack?.stop();
      this.remoteStream.videoTrack?.stop();
      resolve();
    });
  }

  toggleMicrophone(): boolean {
    if (!this.localStream) {
      return false;
    }

    if (this.microphoneMuted) {
      this.microphoneMuted = false;
      this.localStream.audioTrack?.setMuted(false);
    } else {
      this.microphoneMuted = true;
      this.localStream.audioTrack?.setMuted(true);
    }

    return true;
  }

  toggleLocalStream() {
    if (this.localStream) {
      if (this.localStreamMuted) {
        this.localStreamMuted = false;
        this.localStream.audioTrack?.setMuted(false);
        this.localStream.videoTrack?.play('camera');
      } else {
        this.localStreamMuted = true;
        this.localStream.audioTrack?.setMuted(true);
        this.localStream.videoTrack?.stop();
      }
    }
  }

  muteRemoteStream() {
    this.remoteStreamMuted = true;
    if (this.remoteStream) {
      this.remoteStream.audioTrack?.setVolume(0);
    }
  }

  unmuteRemoteStream() {
    this.remoteStreamMuted = false;

    // if (this.remoteStreamMuting) {
    //   this.remoteStream.audioTrack?.setVolume(100);
    // } else {
    this.remoteStream.audioTrack?.setVolume(100);
    // }

    // if (this.remoteStream) {
    //   this.remoteStreamMuting = '';
    // }
  }

  async switchCamera() {
    if (!this.localStream) {
      return;
    }

    const {video: videoDevices} = await this.detectDevices();
    if (videoDevices.length <= 1) {
      return;
    }

    const currentId = this.cameraId || videoDevices[0].id;
    const currentDeviceIndex = videoDevices.findIndex((device) => device.id === currentId);
    const nextDeviceIndex = (currentDeviceIndex + 1) % videoDevices.length;
    const nextDeviceId = videoDevices[nextDeviceIndex].id;

    try {
      await this.agoraSwitchDevice('video', nextDeviceId);
      this.cameraId = nextDeviceId;
    } catch (err) {
      throw err;
    }
  }
}
